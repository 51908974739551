import {
  SET_USER,
  LOG_OUT,
  FETCH_USER_FAVORITES,
  FETCH_USER_NOTIFICATIONS,
  FETCH_USER_SETTINGS,
  FETCH_USER_ORDERS,
  SET_USER_LOCATION, SET_USER_QUEST_ID,
} from "../constants/ActionTypes";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  email: "",
  api_token: "",
  name: "",
  id: null,
  guest_user_id: uuidv4(),
  orders: [],
  favorites: [],
  notifications: [],
  settings: [],
  location: { latitude: 0, longitude: 0 },
  phone:"",
  address:"",
  bio:""
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_USER_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    case FETCH_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case FETCH_USER_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case FETCH_USER_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_USER_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_USER_QUEST_ID:
      return {
        ...state,
        guest_user_id: uuidv4()
      };
    case LOG_OUT:
      return {
        ...state,
        email: "",
        api_token: "",
        name: "",
        id: null,
        guest_user_id: uuidv4(),
        orders: [],
        favorites: [],
        notifications: [],
        settings: [],
      };
    default:
      return state;
  }
};

export default userReducer;
