import React, {useState} from "react";
import {Person, Map, PinDrop, Phone, Email, Payment} from "@material-ui/icons";
import {
    Radio,
    Card,
    InputLabel,
    Input,
    FormControl,
    InputAdornment,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckoutReservation from "../../Components/Reservation/CheckoutReservation";
import styled from 'styled-components';

const StyledOr = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #626161;
  margin-bottom: 20px;
`;

const CartInfo = ({values, errors, touched, FormHandleChange, handleBlur, setReservation}) => {
    const [way, setWay] = useState(values.shippingMethod);
    const handleChange = (event) => {
        setWay(event.target.value);
        setReservation(false);
        FormHandleChange(event);
    };
    const handleReservationChange = (event) => {
        setReservation(true);
        setWay(event.target.value);
        FormHandleChange(event);
    };
    return (
        <div className="cart_info fade-in">
            <div className="cart_info--way_taken">
                <div className="option">
                    <Person/>
                    <span className="option--text">Odbiór osobisty</span>
                    <Radio
                        checked={way === "store"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value="store"
                        name="shippingMethod"
                        inputProps={{"aria-label": "A"}}
                    />
                </div>
                <div className="option">
                    <Map/>
                    <span className="option--text">Adres dostawy</span>
                    <Radio
                        checked={way === "place"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value="place"
                        name="shippingMethod"
                        inputProps={{"aria-label": "A"}}
                    />
                </div>
                <FormHelperText>
                    {errors.shippingMethod && touched.shippingMethod && errors.shippingMethod}
                </FormHelperText>
            </div>
            <StyledOr>
                lub
            </StyledOr>
            <div className="cart_info--way_taken">
                <div className="option">
                    <Map/>
                    <span className="option--text">Zjedz na miejscu - zarezerwuj stolik</span>
                    <Radio
                        checked={way === "reservation"}
                        onChange={handleReservationChange}
                        onBlur={handleBlur}
                        value="reservation"
                        name="shippingMethod"
                        inputProps={{"aria-label": "A"}}
                    />
                </div>
            </div>
            {way === "reservation" && (
                <CheckoutReservation values={values} errors={errors} touched={touched}
                                     FormHandleChange={FormHandleChange} handleBlur={handleBlur}/>
            )}
            <div className="cart_info--form">
                {way === "place" && (
                    <Card>
                        <form>
                            <FormControl>
                                <InputLabel htmlFor="name">Imię i nazwisko</InputLabel>
                                <Input
                                    autoFocus
                                    id="name"
                                    name="name"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    endAdornment={
                                        <InputAdornment>
                                            <Person/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.name && touched.name && errors.name}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Ulica i numer lokalu</InputLabel>
                                <Input
                                    name="address"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    endAdornment={
                                        <InputAdornment>
                                            <PinDrop/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.address && touched.address && errors.address}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Miejscowość</InputLabel>
                                <Input
                                    name="city"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    endAdornment={
                                        <InputAdornment>
                                            <PinDrop/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.city && touched.city && errors.city}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Telefon</InputLabel>
                                <Input
                                    name="phone"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    endAdornment={
                                        <InputAdornment>
                                            <Phone/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.phone && touched.phone && errors.phone}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>E-mail</InputLabel>
                                <Input
                                    name="email"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    endAdornment={
                                        <InputAdornment>
                                            <Email/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.email && touched.email && errors.email}
                                </FormHelperText>
                            </FormControl>
                        </form>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default CartInfo;
