import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import SocialApi from "../../Api/socialApi";
import {toast} from "react-toastify";
import {cart} from "../../Models/Cart";
import {setUser} from "../../actions";
import {useDispatch, useSelector} from "react-redux";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FacebookCallback = () => {
    const { token } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const carts = useSelector((state) => state.cartReducer.cart);
    const user = useSelector((state) => state.userReducer);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        SocialApi.facebookLogin(token,{}).then((response) => {
            toast.success("Logowanie się powiodło.");
            let firstElementReset = 1;
            carts.forEach((item) => {
                cart.addToCart(user,item.food,item.quantity,firstElementReset).then(() => {
                });
                firstElementReset = false;
            });
            dispatch(
                setUser({
                    email: response.data.email,
                    name: response.data.name,
                    api_token: response.data.api_token,
                    id: response.data.id,
                    phone: response.settings?.custom_fields?.bio?.value,
                    address: response.settings?.custom_fields?.bio?.value,
                    bio: response.settings?.custom_fields?.bio?.value,
                })
            );
            history.push("/");
        }).catch((e)=>{
            console.log(e);
            toast.error("Błąd logowania.");
        }).finally(()=>{
            setLoader(false);
        })
    }, [token]);

    return (
        <>
            {loader && (
                <LoaderWrapper>
                    <CircularProgress color="secondary" />
                </LoaderWrapper>
            )}
        </>
    );
}

export default FacebookCallback;
