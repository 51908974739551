import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CardReview from "../../Components/CardReview";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import MarkerIcon from "../../Assets/Maps/marker.png";
import L from 'leaflet';
import "./styles.scss";
import { CircularProgress } from "@material-ui/core";
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
import haversine from "haversine";
import TopImage from "../../Assets/Home/top.jpg";
import restaurantApi from "../../Api/restaurantApi";


const MapWrapper = styled.div`
    min-height: 800px;
    margin: 20px;
`;

const icon = L.icon({
  iconUrl: MarkerIcon,
  iconSize: [36, 36],
  popupAnchor: [0, -25]
});



const Map = () => {
  const positionUser = useSelector((state) => state.userReducer.location);
  const [restaurants, setRestaurants] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let search = query.get('search');
    let delivery = "";
    if(query.has('delivery')){
      delivery = query.get('delivery') === 'true' ? ";available_for_delivery:1" : "";
    }
    const params = {
      search: `address:${search}` + delivery,
      searchFields: 'address:like',
    }
    if(query.has('open')){
      params.is_open = query.get('open') === 'true';
    }
    restaurantApi.getAll(params).then((response)=>{
      const {data: newRestaurants} = response
      setRestaurants(newRestaurants.filter(
          (item) =>
              haversine(
                  {
                    latitude: Number(item.latitude),
                    longitude: Number(item.longitude),
                  },
                  positionUser
              ) <= 80
      )
          .sort((a, b) =>
              b.rate.localeCompare(a.rate, undefined, {
                numeric: true,
                sensitivity: "base",
              })
          )
          .slice(0, 4));
    }).finally(()=>{
    })
  }, [location.search,positionUser]);

  return (
    <div>
      {positionUser.latitude ? (
          <>
            <MapWrapper>
              <MapContainer center={[positionUser.latitude,positionUser.longitude]}
                            style={{
                              height:"800px"
                            }}
                            zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {restaurants.map((restaurant) => (
                    <Marker position={[restaurant.latitude,restaurant.longitude]} icon={icon}>
                      <Popup>
                        <Link
                            to={`/restaurants/${restaurant.id}`}
                            style={{ textDecoration: "none", color: "#000" }}
                        >
                          {restaurant.name}
                        </Link>
                      </Popup>
                    </Marker>
                ))}
              </MapContainer>
            </MapWrapper>
            <div className="container">
              <Grid container spacing={3}>
                {restaurants.map((restaurant) => (
                            <Grid
                                key={restaurant.id}
                                item
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                            >
                              <CardReview
                                  imgSrc={
                                    restaurant.media.length
                                        ? restaurant.media[0].url
                                        ? restaurant.media[0].url
                                            .split(".pl")
                                            .join(".pl/")
                                        : TopImage
                                        : TopImage
                                  }
                                  name={restaurant.name}
                                  id={restaurant.id}
                                  rate={restaurant.rate}
                              />
                            </Grid>
                        ))}
              </Grid>
            </div>
          </>
      ) : (
        <div style={{textAlign: "center"}}>
          <CircularProgress size={20} />{" "}
        </div>
      )}
    </div>
  );
};

export default Map;
