import React, {useEffect, useState} from "react";
import { Card, Button } from "@material-ui/core";
import moment from "moment";
import { Done } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dish from "../../Assets/Customer/dish.png";
import Feedback from "./Feedback";
import { useSelector } from "react-redux";
import userApi from "../../Api/userApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import {getOrderPrice, getPrice, getTotalOrdersPrice} from "../../helpers/order";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MyOrder = () => {
  const user = useSelector((state) => state.userReducer);
  const [orders, setOrders] = useState([]);
  const [statues, setStatues] = useState([]);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [feedback, setFeedback] = useState({ foodOrders: [], visible: false });
  const toFeedbackOrder = (order, foodOrders) => setFeedback({ order, foodOrders, visible: true });
  const hiddenFeedback = () => {
    setFeedback({ foodOrders: [], visible: false });
  };

  useEffect(() => {
    userApi.orderStatusList({
      api_token: user.api_token
    }).then((response) => {
      const {data: newStatues} = response;
      setStatues(newStatues);
    })
    userApi.orders({
      api_token: user.api_token,
      with: "user;foodOrders;foodOrders.food;orderStatus",
      search: user.id,
      searchFields: "user.id:=",
      orderBy: "id",
      sortedBy: "desc",
    }).then((response) => {
      const {data: newOrders} = response;
      setOrders(newOrders);
    }).finally(()=>{
      setLoader(false);
    })
  }, [change]);


  if (feedback.foodOrders.length && feedback.visible) return <Feedback
      order={feedback.order}
      foodOrders={feedback.foodOrders}
      hiddenFeedback={hiddenFeedback}
      setChange={setChange}
      change={change}
  />;
  return (
    <div className={classes.root}>
      {loader && (
          <LoaderWrapper>
            <CircularProgress color="secondary" />
          </LoaderWrapper>
      )}
      {orders.map((order) => (
        <Accordion
          key={order.id}
          expanded={expanded === `panel${order.id}`}
          onChange={handleChange(`panel${order.id}`)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="customer_my_order--title">
              <div className="title--left">
                <h5>{`Zamówienie #${order.id}`}</h5>
                <p>{order.order_status.status}</p>
              </div>
              <div className="title--right">
                <Button onClick={() => toFeedbackOrder(order, order.food_orders)}>
                  {!feedback ? "Wystaw ocenę" : "Zobacz"}
                </Button>
                <div className="title--right--info">
                  <p>{getPrice(getTotalOrdersPrice(order))}</p>
                  <span>
                    {moment(order.created_at).format("DD-M [|] HH:mm a")}
                  </span>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="customer_my_order fade-in">
              <div className="customer_my_order--dish">
                {order.food_orders.map((item, index) => (
                  <Card key={index}>
                    <div className="dish--left">
                      <img
                        src={
                          item.food.media.length
                            ? item.food.media[0].url
                              ? item.food.media[0].url.split(".pl").join(".pl/")
                              : Dish
                            : Dish
                        }
                        alt="dish"
                      />
                      <div className="dish--left--info">
                        <p>{item.food.name}</p>
                        <div>
                          {item.food.restaurant.name}
                        </div>
                      </div>
                    </div>
                    <div className="dish--right">
                      <p>{getPrice(getOrderPrice(item,order.tax))}</p>
                      <span>x{item.quantity}</span>
                    </div>
                  </Card>
                ))}
              </div>

              <div className="customer_my_order--price">
                <div className="price--wrapper">
                  <span>Koszt dostawy</span>
                  <span>{getPrice(order.delivery_fee)}</span>
                </div>
                <div className="price--wrapper">
                  <span>Razem</span>
                  <span>
                    {getPrice(getTotalOrdersPrice(order))}
                  </span>
                </div>
              </div>
              <div className="customer_my_order--status">
                  {statues.map(({status}, index) => (
                      <div className="step" key={index}>
                        <div className="step--wrapper">
                          <div
                              className={`step--icon ${
                                  order.order_status.status === status
                                      ? "active"
                                      : null
                              }`}
                          >
                            <Done />
                          </div>
                          <div className="step--desc">
                            <p>{status}</p>
                            {order.order_status.status === status && (
                               <p>{order.updated_at}</p>
                            )}
                          </div>
                        </div>
                      </div>
                  ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default MyOrder;
