const getTotalFoodOrderPrice = (foodOrder) => {
    let total = foodOrder.price * foodOrder.quantity;
    foodOrder.extras.forEach((extra) => {
        total += extra.price != null ? extra.price : 0;
    });
    return total;
}

export const getTotalOrdersPrice = (order) => {
    let total = 0.0;
    order.food_orders.forEach((foodOrder) => {
        total += getTotalFoodOrderPrice(foodOrder)
    });
    total += order.tax * total / 100;
    total += order.delivery_fee;
    return total;
}

export const getOrderPrice = (foodOrder, tax) => {
    let total = foodOrder.price;
    foodOrder.extras.forEach((extra) => {
        total += extra.price != null ? extra.price : 0;
    });
    total = (total + ((total*tax)/100));
    return total;
}

export const getPrice = (price) => {
    if(price == null){
        price= 0.0;
    }
    return `${price.toFixed(2)} PLN`;
}
