import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Home from "../Pages/Home";
import Sign from "../Pages/Sign";
import Map from "../Pages/Map";
import Restaurant from "../Pages/Restaurant";
import Cart from "../Pages/Cart";
import TabsCustomer from "../Pages/Customer";

import Regulations from "../Pages/Regulations";
import PrivacyPolicy from "../Pages/Privacy Policy";
import CookiePolicy from "../Pages/CookiePolicy";
import RestaurantZone from "../Pages/RestaurantZone";
import Benefit from "../Pages/BenefitRestaurant";
import FAQ from "../Pages/FAQ";
import ONas from "../Pages/ONas";
import ScrollToTop from "../Components/ScrollToTop";
import reviewApi from "../Api/reviewApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFood,
  getAllRestaurantReview,
  getAllFoodReview,
  getFaqs,
  getFaqCategories,
  getRegulations,
  getPrivacyPolicy,
  getCookiePolicy,
  getBenefit,
  getONas,
  getRestaurantZone,
  fetchAllRestaurant,
  getUserOrders,
  getUserSettings,
  setUserLocation, resetCart, getContact,
} from "../actions/";
import foodApi from "../Api/foodApi";
import faqApi from "../Api/faqApi";
import otherApi from "../Api/otherApi";
import restaurantApi from "../Api/restaurantApi";
import userApi from "../Api/userApi";
import { SumPrice } from "../constants/functions";
import Password from "../Pages/Password";
import RestaurantsList from "../Pages/Restaurant/RestaurantsList";
import {addToCartUnsafe} from "../actions";
import {cart} from "../Models/Cart";
import {toast} from "react-toastify";
import AfterPayment from "../Pages/AfterPayment";
import Join from "../Pages/Join";
import BrokerageRegulations from "../Pages/Regulations/BrokerageRegulations";
import Contact from "../Pages/Contact";
import FacebookCallback from "../Pages/Social/FacebookCallback";
import GoogleCallback from "../Pages/Social/GoogleCallback";

const App = () => {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);

  const fetchReview = () => {
    Promise.all([
      reviewApi.getRestaurant({ with: "user" }),
      reviewApi.getFood({ with: "user" }),
    ]).then((res) => {
      dispatch(getAllFoodReview(res[1].data));
      dispatch(getAllRestaurantReview(res[0].data));
    });
  };

  const fetchFaqs = () => {
    Promise.all([faqApi.getFaqs(), faqApi.getFaqCategories()]).then((res) => {
      dispatch(getFaqs(res[0].data));
      dispatch(getFaqCategories(res[1].data));
    });
  };

  const fetchOther = () => {
    Promise.all([
      otherApi.getRegulations(),
      otherApi.getPrivacyPolicy(),
      otherApi.getCookiePolicy(),
      otherApi.getBenefit(),
      otherApi.getONas(),
      otherApi.getRestaurantZone(),
      otherApi.getContact(),
    ]).then((res) => {
      dispatch(getRegulations(res[0].data));
      dispatch(getPrivacyPolicy(res[1].data));
      dispatch(getCookiePolicy(res[2].data));
      dispatch(getBenefit(res[3].data));
      dispatch(getONas(res[4].data));
      dispatch(getRestaurantZone(res[5].data));
      dispatch(getContact(res[6].data));
    });
  };

  const fetchRestaurants = async () => {
    try {
      const restaurantList = await restaurantApi.getAll();
      dispatch(fetchAllRestaurant(restaurantList.data));
    } catch (error) {
      throw error;
    }
  };

  const fetchFood = async () => {
    const foods = await foodApi.getAll();
    dispatch(getAllFood(foods.data));
  };

  useEffect(() => {
    fetchRestaurants();
    fetchReview();
    fetchFood();
    fetchFaqs();
    fetchOther();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      },(error)=>console.log(error), {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    }
    else{
      toast.error("Geolokalizacja jest wymagana do zamówienia");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetCart());
    if (userReducer.api_token) {
      Promise.all([
        userApi.orders({
          api_token: userReducer.api_token,
          with: "user;foodOrders;foodOrders.food;orderStatus",
          search: userReducer.id,
          searchFields: "user.id:=",
          orderBy: "id",
          sortedBy: "desc",
        }),
        userApi.settings({ api_token: userReducer.api_token }),
        cart.getCart(userReducer)
      ]).then((res) => {
        dispatch(
          getUserOrders(
            res[0].data.map((item) => {
              return { ...item, total: SumPrice(item.food_orders) };
            })
          )
        );
        dispatch(getUserSettings(res[1].data));
        res[2].data.map((item)=>{
          dispatch(addToCartUnsafe(item))
        })
      });
    }else{
      cart.getCart(userReducer).then((response) => {
        response.data.map((item)=>{
          dispatch(addToCartUnsafe(item))
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReducer.api_token,userReducer.guest_user_id]);
  return (
    <BrowserRouter>
      <>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/sign" component={Sign} />
          <Route exact path="/reset" component={Password} />
          <Route exact path="/maps" component={Map} />
          <Route exact path="/restaurants" component={RestaurantsList} />
          <Route path="/restaurants/:id" component={Restaurant} />
          <Route exact path="/cart" render={() => <Cart />} />
          <Route exact path="/customer" component={TabsCustomer} />
          <Route exact path="/regulations" render={() => <Regulations />} />
          <Route exact path="/brokerage-regulations" render={() => <BrokerageRegulations />} />
          <Route
            exact
            path="/privacy-policy"
            render={() => <PrivacyPolicy />}
          />
          <Route exact path="/cookie-policy" render={() => <CookiePolicy />} />
          <Route exact path="/kontakt" render={() => <Contact />} />
          <Route
            exact
            path="/restaurant-zone"
            render={() => <RestaurantZone />}
          />
          <Route exact path="/benefit" render={() => <Benefit />} />
          <Route exact path="/faq" render={() => <FAQ />} />
          <Route exact path="/o-nas" render={() => <ONas />} />
          <Route exact path="/dolacz" render={() => <Join />} />
          <Route exact path="/po-platnosci" render={() => <AfterPayment />} />
          <Route path="/logowanie/fb/:token" render={()=><FacebookCallback/>} />
          <Route path="/logowanie/google/:token" render={()=><GoogleCallback/>} />

        </Switch>
        <Footer />
      </>
    </BrowserRouter>
  );
};

export default App;
