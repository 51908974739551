import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import "./style.scss";
import {ContactForm} from "../../Components/Contact/ContactForm";
import styled from "styled-components";
import {toast} from "react-toastify";
import contactApi from "../../Api/contactApi";

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media(max-width: 600px){
    display: flex;
    flex-direction: column;
  }
`;

const ContactContent = styled.div`
  text-align: center;
  background: #ffffff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 20px;
`;

const AdditionalInfoWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`;

const AdditionalInfo = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.color ? props.color: '#626161'};
`;

const Contact = () => {
    return (
        <div className="cookie_policy">
            <div className="container">
                <Breadcrumb title="Kontakt"/>
                <ContactWrapper>
                    <ContactContent>
                        <ContactForm
                            title="Klient - napisz do nas!"
                            color="#F79238"
                            onSubmit={(values, setLoading, resetForm) => {
                                setLoading(true);
                                contactApi.sentPrivate(values).then(()=>{
                                    resetForm({
                                        name: "",
                                        email: "",
                                        phone: "",
                                        title: "",
                                        description: "",
                                    });
                                    toast.success("Kontakt wysłany.");
                                }).catch(()=>{
                                    toast.error("Coś poszło nie tak.");
                                }).finally(()=>{
                                    setLoading(false);
                                });
                            }}
                        />
                    </ContactContent>
                    <ContactContent>
                        <ContactForm
                            title="Restauracja - napisz do nas!"
                            color="#35A788"
                            onSubmit={(values, setLoading, resetForm) => {
                                setLoading(true);
                                contactApi.sentRestaurant(values).then(()=>{
                                    resetForm({
                                        name: "",
                                        email: "",
                                        phone: "",
                                        title: "",
                                        description: "",
                                    });
                                    toast.success("Kontakt wysłany.");
                                }).catch(()=>{
                                    toast.error("Coś poszło nie tak.");
                                }).finally(()=>{
                                    setLoading(false);
                                });
                            }}
                        />
                    </ContactContent>
                </ContactWrapper>
                <AdditionalInfoWrapper>
                    <AdditionalInfo>lub napisz na nasz e-mail</AdditionalInfo>
                    <AdditionalInfo color="#35A788">kontakt@bookeating.pl</AdditionalInfo>
                </AdditionalInfoWrapper>
            </div>
        </div>
    );
};

export default Contact;
