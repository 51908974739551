import ReservationForm from "./Form";
import React, {useState} from "react";
import {Card} from "@material-ui/core";
import styled from 'styled-components';
import reservationApi from "../../Api/reservationApi";
import {toast} from "react-toastify";

const ReservationInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const ReservationWrapper = styled.div`
    display: flex;
    justify-content: center;
    .customer_settings--form{
      width: 70%;
      @media(max-width: 600px){
        width: 100%;
      }
    }
`;

const StyledCard = styled(Card)`
    padding: 50px !important;
      @media(max-width: 600px){
        padding: 10px !important;
      }
    text-align: center;
    h2{
      margin: 0;
    }
`;

const MenuReservation = ({restaurantName, restaurantId}) => {
    const [loader, setLoader] = useState(false);
    const [reservation, setReservation] = useState(false);

    const submit = (values, {resetForm}) => {
        setLoader(true);
        values.restaurant_id = restaurantId;
        reservationApi.store(values).then(()=>{
            resetForm({
                number_of_people: "",
                reservation_date: "",
                reservation_time: "",
                wishes: "",
                phone: "",
                email: ""
            });
            toast.success("Rezerwacja dodana.");
            setReservation(true);
        }).catch(()=>{
            toast.error("Coś poszło nie tak.");
        }).finally(()=>{
            setLoader(false);
        });
    }

    return (
        <>
            <ReservationInfo>
                <h2>{restaurantName}</h2>
                <span>Jeśli chcesz zarezerwować stolik wraz z menu przejdź do Menu - zamów, dodaj wybrane dania do koszyka.</span>
            </ReservationInfo>
            <ReservationWrapper>
                <div className="customer_settings--form cart_info--form">
                    <StyledCard>
                        <h2>Zarezerwuj stolik</h2>
                        {reservation ? (
                            <p>Rezerwacja dodana.</p>
                        ): (
                            <ReservationForm onSubmit={submit} loader={loader} initialValues={{
                                number_of_people: "",
                                reservation_date: "",
                                reservation_time: "",
                                wishes: "",
                                phone: "",
                                email: "",
                            }}/>
                        )}
                    </StyledCard>
                </div>
            </ReservationWrapper>
        </>
    );
}

export default MenuReservation;
