import Geocode from "react-geocode";

class ReverseGeocode {
    constructor() {
        Geocode.setApiKey("AIzaSyCIC2lPsBJe8HDVD_TaPbSaqoXXYaxGeAg");
        Geocode.setLanguage("pl");
        Geocode.setRegion("pl");
        Geocode.enableDebug();
    }

    getAddress = (lat, lng, responseCallback, errorCallback, finallyCallback) => {
        Geocode.fromLatLng(lat, lng).then(
            responseCallback,
            errorCallback
        ).finally(finallyCallback);
    }
}

export const geocode = new ReverseGeocode();
