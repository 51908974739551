import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import _ from "lodash";
import { useSelector } from "react-redux";

import "./style.scss";

const PrivacyPolicy = () => {
  const privacy = useSelector((state) => state.otherReducer.privacy_policy);
  return (
    <div className="privacy_policy">
      <div className="container">
        <Breadcrumb title="Polityka prywatności" />
        {!_.isEmpty(privacy) && (
          <div className="privacy_policy--content">
            <div dangerouslySetInnerHTML={{ __html: privacy.question }} />
            <div dangerouslySetInnerHTML={{ __html: privacy.answer }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
