import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

const RedirectPage = ({ to }) => {
  const history = useHistory();
  useEffect(() => {
    history.push(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default RedirectPage;
