import { combineReducers } from "redux";
import restaurantReducer from "./restaurant";
import userReducer from "./user";
import reviewReducer from "./review";
import foodReducer from "./food";
import faqReducer from "./faq";
import otherReducer from "./other";
import cartReducer from "./cart";

const rootReducer = combineReducers({
  data: restaurantReducer,
  userReducer,
  reviewReducer,
  foodReducer,
  faqReducer,
  otherReducer,
  cartReducer
});

export default rootReducer;
