import React from "react";
import {Grid, Typography} from "@material-ui/core";
import MenuCard from "../../Components/MenuRestaurant/MenuCard";
import Menu from "../../Assets/Restaurant/menu.svg";
import Food from "../../Assets/Restaurant/food.png";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const MenuList = () => {
    const {id} = useParams();
    const foods = useSelector((state) => state.foodReducer.foods);
    return (
        <>
            <div className="d-flex mt40 mb30">
                <img src={Menu} className="mr5" alt=""/>
                <Typography component={"span"} className="top">
                    Menu
                </Typography>
            </div>
            <Grid container spacing={5}>
                {foods
                    .filter((item) => item.restaurant_id === Number(id))
                    .map((food) => (
                        <Grid item key={food.id} xs={12} sm={6} md={4}>
                            <MenuCard
                                imgSrc={
                                    food.media.length
                                        ? food.media[0].url
                                            ? food.media[0].url.split(".pl").join(".pl/")
                                            : Food
                                        : Food
                                }
                                name={food.name}
                                desc={food.description}
                                price={food.price}
                                restaurantId={food.restaurant_id}
                                id={food.id}
                            />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

export default MenuList;
