import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import _ from "lodash";
import { useSelector } from "react-redux";

import "./style.scss";

const RestaurantZone = () => {
  const restaurantZone = useSelector(
    (state) => state.otherReducer.restaurant_zone
  );
  return (
    <div className="restaurant_zone">
      <div className="container">
        <Breadcrumb title="Strefa Restauracji" />
        {!_.isEmpty(restaurantZone) && (
          <div className="restaurant_zone--content">
            <div
              dangerouslySetInnerHTML={{ __html: restaurantZone.question }}
            />
            <div dangerouslySetInnerHTML={{ __html: restaurantZone.answer }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantZone;
