import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
// import Button from "@material-ui/core/Button";

import ArrowForward from "@material-ui/icons/ArrowForward";
// Styles
import "./styles.scss";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const CardReview = (props) => {
  const { id, rate, imgSrc, name } = props;

  return (
    <Link
      to={`/restaurants/${id}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Card className="card_review">
        <CardActionArea>
          <CardMedia image={imgSrc} title={name} className="media" />
          <CardContent>
            <Grid container>
              <Grid item xs={9}>
                <Typography gutterBottom className="content">
                  {name}
                </Typography>
                <Box borderColor="transparent">
                  <Rating
                    name="read-only"
                    value={Number(rate)}
                    readOnly
                    classes={{
                      root: "starColor",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} className="btnCenter">
                <p className="cardBtn">
                  <ArrowForward className="iconColor" />
                </p>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default CardReview;
