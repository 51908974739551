import React from "react";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { Card } from "@material-ui/core";
import {
  Delete,
  AddCircleOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";

import ImageDish from "../../Assets/Cart/cart_image.png";
import { useDispatch, useSelector } from "react-redux";
import {incrementQty, removeFromCart} from "../../actions";
import {cart} from "../../Models/Cart";
import {toast} from "react-toastify";

const CartItem = ({ cart_item, onRemove, onChangeNumber }) => {
  const { id, qty, food } = cart_item;
  return (
    <div className="cart_item ">
      <Card>
        <img
          src={
            food.media.length
              ? food.media[0].url
                ? food.media[0].url.split(".pl").join(".pl/")
                : ImageDish
              : ImageDish
          }
          alt="dish"
        />
        <div className="cart_item--content">
          <p className="title">{food.name}</p>
          <p className="price">{food.price.toFixed(2)} PLN</p>
        </div>
        <div className="actions">
          <div className="actions--fill_number">
            <button
              className="icon_wrapper"
              onClick={() => onChangeNumber(cart_item, "plus")}
            >
              <AddCircleOutline />
            </button>
            <span>{qty}</span>
            <button
              className="icon_wrapper"
              disabled={qty < 2}
              onClick={() => onChangeNumber(cart_item, "minus")}
            >
              <RemoveCircleOutline />
            </button>
          </div>
          <span className="icon_wrapper" onClick={() => onRemove(id)}>
            <Delete />
          </span>
        </div>
      </Card>
    </div>
  );
};

const CartList = () => {
  const carts = useSelector((state) => state.cartReducer.cart);
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);

  const onRemoveCartItem = (id) => {
    cart.remove(id, userReducer).then(() => {
      toast.success("Usunięto z koszyka");
      dispatch(removeFromCart(id));
    });
  };

  const onChangeNumberCartItem = (cartItem, type) => {
    if (type === "plus") {
      cart.incrementQty(cartItem,userReducer).then(() => {
        toast.success("Zwiększono ilość w koszyku");
        dispatch(incrementQty(cartItem.id, 1));
      });
    } else {
      if((cartItem.qty - 1) >= 1){
        cart.decrementQty(cartItem,userReducer).then(() => {
          toast.success("Zmniejszono ilość w koszyku");
          dispatch(incrementQty(cartItem.id, -1));
        });
      }
    }
  };

  return (
    <div className="cart_list fade-in">
      <div className="cart_list--title">
        <ShoppingCart />
        <span>Koszyk</span>
      </div>
      <div className="cart_list--content">
        {carts.map((cart) => (
          <CartItem
            key={cart.id}
            cart_item={cart}
            onRemove={onRemoveCartItem}
            onChangeNumber={onChangeNumberCartItem}
          />
        ))}
      </div>
    </div>
  );
};

export default CartList;
