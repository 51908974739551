import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Styles
import "./styles.scss";
import { Link } from "react-router-dom";

const Opinion = (props) => {
  const { restaurantId, avatar, name, content, rate, food_id } = props;

  return (
    <Link
      to={
        food_id
          ? `/restaurants/${restaurantId}/food#${food_id}`
          : `/restaurants/${restaurantId}`
      }
      style={{ textDecoration: "none", display: "block" }}
    >
      <Card
        classes={{ root: "cardOpinie" }}
        style={{ backgroundColor: "transparent", boxShadow: "unset" }}
      >
        <CardHeader
          avatar={
            <Avatar
              style={{ width: 63, height: 63 }}
              aria-label="recipe"
              src={avatar}
            />
          }
          action={
            <IconButton aria-label="settings">
              <div
                className="d-flex"
                style={{
                  padding: 5,
                  borderRadius: 10,
                  backgroundColor: "#D1402A",
                }}
              >
                <Typography className="fiveColor" style={{ color: "#fff" }}>
                  {rate}
                </Typography>
                <StarIcon
                  className="starColor1"
                  style={{ fill: "#fff", fontSize: 22 }}
                />
              </div>
            </IconButton>
          }
          title={name}
          subheader={content}
          classes={{
            action: "cardAction",
            root: "cardHeader",
          }}
        />
      </Card>
    </Link>
  );
};

export default Opinion;
