import axiosClient from "./axiosClient";
import qs from "qs";

const contactApi = {
    sentPrivate: (data) => {
        const url = "/contact/private";
        return axiosClient.post(url, qs.stringify(data));
    },
    sentRestaurant: (data) => {
        const url = "/contact/restaurant";
        return axiosClient.post(url, qs.stringify(data));
    },
}

export default contactApi;
