export const FETCH_ALL_RESTAURANT = "FETCH_ALL_RESTAURANT";
export const FETCH_SINGLE_RESTAURANT = "FETCH_SINGLE_RESTAURANT";

export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const FETCH_USER_ORDERS = "FETCH_USER_ORDERS";
export const FETCH_USER_FAVORITES = "FETCH_USER_FAVORITES";
export const FETCH_USER_SETTINGS = "FETCH_USER_SETTINGS";
export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const SET_USER_LOCATION = "SET_USER_LOCATION";

export const FETCH_ALL_FOOD_REVIEW = "FETCH_ALL_FOOD_REVIEW";
export const FETCH_ALL_RESTAURANT_REVIEW = "FETCH_ALL_RESTAURANT_REVIEW";

export const FETCH_ALL_FOOD = "FETCH_ALL_FOOD";

export const FETCH_FAQ_CATEGORIES = "FETCH_FAQ_CATEGORIES";
export const FETCH_FAQS = "FETCH_FAQS";

export const FETCH_REGULATIONS = "FETCH_REGULATIONS";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";
export const FETCH_COOKIE_POLICY = "FETCH_COOKIE_POLICY";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const FETCH_BENEFIT = "FETCH_BENEFIT";
export const FETCH_O_NAS = "FETCH_O_NAS";
export const FETCH_RESTAURANT_ZONE = "FETCH_RESTAURANT_ZONE";

export const ADD_TO_CART = "ADD_TO_CART";
export const RESET_CART = "RESET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const SET_USER_QUEST_ID = "SET_USER_QUEST_ID";
