import React from "react";
import {Card, Input, InputAdornment, InputLabel, TextField} from "@material-ui/core";
import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";
import {Email, Person, Phone} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";

const ReservationWrapper = styled.div`
  display: flex;
  justify-content: center;

  .customer_settings--form {
    width: 100%;
  }
`;

const StyledCard = styled(Card)`
  padding: 20px !important;
  text-align: center;

  h2 {
    margin: 0;
  }
`;

const StyledForm = styled.form`
  max-width: 100% !important;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }
`;

const CheckoutReservation = ({values, errors, touched, FormHandleChange, handleBlur}) => {

    return (
        <>
            <ReservationWrapper>
                <div className="customer_settings--form cart_info--form">
                    <StyledCard>
                        <StyledForm>
                            <FormControl>
                                <InputLabel htmlFor="number_of_people">Ilość osób</InputLabel>
                                <Input
                                    id="number_of_people"
                                    name="number_of_people"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.number_of_people}
                                    endAdornment={
                                        <InputAdornment>
                                            <Person/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.number_of_people && touched.number_of_people && errors.number_of_people}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <TextField label="Data" name="reservation_date" type="date"
                                           value={values.reservation_date} onChange={FormHandleChange}/>
                                <FormHelperText>
                                    {errors.reservation_date && touched.reservation_date && errors.reservation_date}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <TextField label="Godzina" name="reservation_time" type="time"
                                           value={values.reservation_time} onChange={FormHandleChange}/>
                                <FormHelperText>
                                    {errors.reservation_time && touched.reservation_time && errors.reservation_time}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Telefon</InputLabel>
                                <Input
                                    name="reservation_phone"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.reservation_phone}
                                    endAdornment={
                                        <InputAdornment>
                                            <Phone/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.reservation_phone && touched.reservation_phone && errors.reservation_phone}
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <InputLabel>E-mail</InputLabel>
                                <Input
                                    name="reservation_email"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.reservation_email}
                                    endAdornment={
                                        <InputAdornment>
                                            <Email/>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.reservation_email && touched.reservation_email && errors.reservation_email}
                                </FormHelperText>
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="wishes">Specjalne życzenia</InputLabel>
                                <Input
                                    id="wishes"
                                    name="wishes"
                                    onChange={FormHandleChange}
                                    onBlur={handleBlur}
                                    value={values.wishes}
                                    endAdornment={
                                        <InputAdornment>

                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {errors.wishes && touched.wishes && errors.wishes}
                                </FormHelperText>
                            </FormControl>
                        </StyledForm>
                    </StyledCard>
                </div>
            </ReservationWrapper>
        </>
    );
}

export default CheckoutReservation;
