import { FETCH_FAQ_CATEGORIES, FETCH_FAQS } from "../constants/ActionTypes";

const initialState = {
  faq_categories: [],
  faqs: [],
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAQ_CATEGORIES:
      return { ...state, faq_categories: action.payload };
    case FETCH_FAQS:
      return { ...state, faqs: action.payload };
    default:
      return state;
  }
};

export default faqReducer;
