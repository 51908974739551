import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import image from "../../Assets/Join/join.png";
import "./style.scss";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 667px;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Join = () => {
  return (
    <div className="onas">
      <div className="container">
        <Breadcrumb title="Dołącz" />
          <Content>
              <span className="join__title">Zostań <br/> naszym partnerem !</span>
              <span className="join__subTitle">Zyskaj nowych klientów !</span>
              <Link href="https://panelrestauracji.pl/register" className="link">
                  <Button className="btn">
                      Zarejestruj się
                  </Button>
              </Link>
          </Content>
      </div>
    </div>
  );
};

export default Join;
