import React from "react";
import {
  Avatar,
  Card,
  Button,
  TextField,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { AssignmentInd } from "@material-ui/icons";
import {Formik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import userApi from "../../Api/userApi";
import {setUser} from "../../actions";
import {toast} from "react-toastify";

const UpdateSchema = Yup.object().shape({
  email: Yup.string()
      .email("Niepoprawny format maila.")
      .required("Email jest wymagany."),
  name: Yup.string()
      .required("Hasło jest wymagane."),
});

const Settings = () => {
  const userReducer = useSelector(state => state.userReducer);
  const dispatch = useDispatch();
  const handleAvatar = (e) => {};

  const updateDate = async (values) => {
    userApi.updateData(userReducer.id, userReducer.api_token, values)
        .then((response)=> {
            const user = response.data;
            userReducer.email = user.email;
            userReducer.name = user.name;
            userReducer.settings.custom_fields.phone.value = user.custom_fields?.phone?.value;
            userReducer.settings.custom_fields.address.value = user.custom_fields?.address?.value;
            userReducer.settings.custom_fields.bio.value = user.custom_fields?.bio?.value;
            dispatch(
                setUser(userReducer)
            );
            toast.success("Zaktualizowano dane.");
        })
  }

  return (
    <div className="customer_settings fade-in">
      <div className="customer_settings--avatar">
        <label htmlFor="upload-photo">
          <Avatar />

          <input
            onChange={handleAvatar}
            id="upload-photo"
            name="upload-photo"
            type="file"
            style={{ display: "none" }}
          />
        </label>
        <span>{userReducer.name}</span>
      </div>
      <div className="customer_settings--info">
        <div className="title">
          <AssignmentInd />
          <span>O mnie</span>
        </div>
      </div>
      <div className="customer_settings--form">
        <Card>
          <p className="form_title">Zmień dane</p>
          <Formik
              initialValues={{
                email: userReducer.email,
                name: userReducer.name,
                phone: userReducer.settings?.custom_fields?.phone?.value,
                address: userReducer.settings?.custom_fields?.address?.value,
                bio: userReducer.settings?.custom_fields?.bio?.value,
              }}
              validationSchema={UpdateSchema}
              onSubmit={updateDate}
          >
            {({
                handleSubmit,
                errors,
                values,
                touched,
                handleBlur,
                handleChange,
              }) => (
                <>
                  <form>
                    <FormControl>
                      <TextField label="Imię" name="name" value={values.name} onChange={handleChange} />
                      <FormHelperText>
                        {errors.name && touched.name && errors.name}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <TextField label="E-mail" name="email" value={values.email} onChange={handleChange} />
                      <FormHelperText>
                        {errors.email && touched.email && errors.email}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <TextField label="Telefon" name="phone" value={values.phone} onChange={handleChange} />
                      <FormHelperText>
                        {errors.phone && touched.phone && errors.phone}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <TextField label="Adres" name="address" value={values.address} onChange={handleChange} />
                      <FormHelperText>
                        {errors.address && touched.address && errors.address}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <TextField label="O mnie" name="bio" value={values.bio} onChange={handleChange} />
                      <FormHelperText>
                        {errors.bio && touched.bio && errors.bio}
                      </FormHelperText>
                    </FormControl>
                    <Button onClick={handleSubmit}>Zapisz</Button>
                  </form>
                </>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

export default Settings;
