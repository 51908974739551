import axiosClient from "./axiosClient";
import qs from "qs";

const reservationApi = {
    store: (data) => {
        const url = "/reservations";
        return axiosClient.post(url, qs.stringify(data));
    },
}

export default reservationApi;
