import React, { useState } from "react";
import { Star, StarBorder } from "@material-ui/icons";
import { Card, TextField, Button } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import userApi from "../../Api/userApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const FeedbackItem = ({ order, dataRestaurant, hiddenFeedback, setChange, change }) => {
  const user = useSelector((state) => state.userReducer);
  const [rateStarRestau, setRateStarRestau] = useState(order.restaurant_rate ?? 0);
  const [review, setReview] = useState(order.restaurant_review_text);
  const [added, setAdd] = useState(false);
  const rateStarRestaurant = (event, value) => {
    setRateStarRestau(value);
  };
  const reviewRestaurant = (event) => {
    setReview(event.target.value);
  };
  const [loader, setLoader] = useState(false);

  const addReview = (text, rate) => {
    if(rate != 0){
      setLoader(true);
      userApi.addRestaurantReview({
        id: null,
        review: text,
        rate: rate,
        order_id: order.id,
        user_id: user.id,
        restaurant_id: dataRestaurant.id
      },{
        api_token: user.api_token
      }).then(() => {
        setAdd(true);
        setChange(change+1);
        toast.success("Dodano do ocenę.");
      }).finally(()=>{
        setLoader(false);
      })
    }
  }

  return (
    <div className="my_order_feedback fade-in">
      <p onClick={()=>{hiddenFeedback()}}>{'< Wróć'}</p>
      <div className="my_order_feedback--title">
        <div className="rate_star">
          <span>{Number(dataRestaurant.rate)}</span>
          <Star />
        </div>
        <p>{dataRestaurant.name}</p>
      </div>
      <div className="form_feedback">
        <Card>
          <p className="form_feedback--title">
            {!(order.restaurant_rate == 0 && !added) ?  "Wystawiłeś już ocenę restauracji" : "Jak oceniasz tę restaurację?"}
          </p>
          <TextField
            rows={4}
            multiline
            variant="outlined"
            rowsMax={6}
            disabled={!(order.restaurant_rate == 0 && !added)}
            placeholder="Napisz opinię"
            defaultValue={review}
            onChange={reviewRestaurant}
          />
          <Rating
            name="restaurant"
            value={rateStarRestau}
            onChange={rateStarRestaurant}
            disabled={!(order.restaurant_rate == 0 && !added)}
            precision={0.5}
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
          {order.restaurant_rate == 0 && !added && (
              <>
                {loader ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <Button onClick={()=>{addReview(review, rateStarRestau)}}>Gotowe</Button>
                )}
              </>
          )}
        </Card>
      </div>
    </div>
  );
};

const Feedback = ({ order, foodOrders, hiddenFeedback, setChange, change }) => {
  return (
    <>
      {foodOrders.map((item, index) => {
        if(index === 0){
          return  <FeedbackItem key={index} order={order} dataRestaurant={item.food.restaurant} hiddenFeedback={hiddenFeedback} setChange={setChange} change={change} />
        }
      })}
    </>
  );
};

export default Feedback;
