import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useLocation } from "react-router-dom";

import "./styles.scss";

const Breadcrumb = (props) => {
  const location = useLocation();
  return (
    <Breadcrumbs
      className="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link color="inherit" href="/">
        Strona główna
      </Link>
      <Link color="inherit" href={location.pathname}>
        {props.title}
      </Link>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
