import axiosClient from "./axiosClient";

export const addressApi = {
    addAddress: (data, params) => {
        const url = "/delivery_addresses";
        let bodyFormData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value);
        }
        return axiosClient.post(url, bodyFormData, { params });
    }
}
