import {
  ADD_TO_CART,
  DECREMENT_QTY, INCREMENT_QTY,
  REMOVE_FROM_CART, RESET_CART,
} from "../constants/ActionTypes";

const initialState = {
  cart: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.product.quantity,
            sum: action.product.food.price * action.product.quantity,
          },
        ],
      };

    case RESET_CART:
      return {
        ...state,
        cart: []
      };


    case INCREMENT_QTY:
      const cart = state.cart.map((item) => {
        if(item.id === action.cartId){
          item.quantity += action.qty;
          item.qty += action.qty;
          item.sum = item.food.price * item.quantity;
        }
        return item;
      });
      return { ...state, cart };

    case REMOVE_FROM_CART:
      return {
        cart: state.cart.filter((item) => item.id !== action.cartId),
      };

    default:
  }
  return state;
};

export default cartReducer;
