import axiosClient from "./axiosClient";
import qs from "qs";

const userApi = {
  login: (data) => {
    const url = "/login";
    return axiosClient.post(url, qs.stringify(data));
  },

  register: (data) => {
    const url = "/register";
    if(data){
      data.domain = 2;
    }
    else{
      data = {domain: 2};
    }
    return axiosClient.post(url, qs.stringify(data));
  },

  resetPassword: (data) => {
    const url = "/send_reset_link_email";
    return axiosClient.post(url,qs.stringify(data));
  },

  logout: (params) => {
    const url = "/logout";
    return axiosClient.get(url, { params });
  },

  orders: (params) => {
    if(params){
      params.domain = 2;
    }
    else{
      params = {domain: 2};
    }
    const url = "/orders";
    return axiosClient.get(url, { params });
  },

  orderStatusList: (params) => {
    const url = "/order_statuses";
    return axiosClient.get(url, { params });
  },

  addRestaurantReview: (data, params) => {
    const url = "/restaurant_reviews";
    let bodyFormData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value);
    }
    return axiosClient.post(url, bodyFormData, { params });
  },

  favorites: (params) => {
    if(params){
      params.domain = 2;
    }
    else{
      params = {domain: 2};
    }
    const url = "/favorites";
    return axiosClient.get(url, { params });
  },

  addFavorites: (data, params) => {
    const url = `/favorites`;
    if(data){
      data.domain = 2;
    }
    else{
      data = {domain: 2};
    }
    let bodyFormData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value);
    }
    return axiosClient.post(url, bodyFormData , { params });
  },

  removeFavorites: (id, params) => {
    const url = `/favorites/${id}`;
    return axiosClient.delete(url, { params });
  },

  settings: (params) => {
    const url = "/user";
    return axiosClient.get(url, { params });
  },

  notifications: (params) => {
    const url = "/notifications";
    return axiosClient.get(url, { params });
  },

  updateData: (id, token, data) => {
    const url = `/users/${id}?api_token=${token}`;
    let bodyFormData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value);
    }
    return axiosClient.post(url, bodyFormData);
  },
};

export default userApi;
