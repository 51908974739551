import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Breadcrumb from "../../Components/Breadcrumbs";

import "./style.scss";

const Regulations = () => {
  const regulations = useSelector((state) => state.otherReducer.regulations);
  return (
    <div className="regulations">
      <div className="container">
        <Breadcrumb title="Regulamin" />
        {!_.isEmpty(regulations) && (
          <div className="regulation--content">
            <div dangerouslySetInnerHTML={{ __html: regulations.question }} />
            <div dangerouslySetInnerHTML={{ __html: regulations.answer }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Regulations;
