import FormControl from "@material-ui/core/FormControl";
import {Button, Input, InputAdornment, InputLabel, TextField} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Formik} from "formik";
import React from "react";
import * as Yup from "yup";
import styled from 'styled-components';
import {Email, Person, Phone} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

const moment = require("moment");
const ValidationSchema = Yup.object().shape({
    number_of_people: Yup.number()
        .required("Ilość osób jest wymagana"),
    reservation_date: Yup.date()
        .required("Data rezerwacji jest wymagana"),
    reservation_time: Yup.string()
        .required("Godzina rezerwacji jest wymagana")
        .test("is-greater", "Można rezerować najwcześniej 30 min przed.", function(value) {
            const start = new moment().add(30, 'minutes').format("HH:mm");
            return moment(value, "HH:mm").isSameOrAfter(moment(start, "HH:mm"));
        }),
    phone: Yup.string()
        .required("Telefon jest wymagany").min(9,'Niepoprawny format telefonu'),
    email: Yup.string()
        .email("Niepoprawny format maila.")
        .required("E-mail jest wymagany"),
});

const StyledForm = styled.form`
  max-width: 100% !important;
  width: 100%;
    .MuiFormControl-root{
      width: 100%;
    }
`;

const StyledButton = styled(Button)`
  align-self: center;
`;


const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ReservationForm = ({onSubmit, initialValues, loader, displaySubmitButton = true}) => {

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  errors,
                  values,
                  touched,
                  handleBlur,
                  handleChange,
              }) => (
                <>
                    <StyledForm>
                        <FormControl>
                            <InputLabel htmlFor="number_of_people">Ilość osób</InputLabel>
                            <Input
                                id="number_of_people"
                                name="number_of_people"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.number_of_people}
                                endAdornment={
                                    <InputAdornment>
                                        <Person/>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {errors.number_of_people && touched.number_of_people && errors.number_of_people}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <TextField label="Data" name="reservation_date" type="date" value={values.reservation_date} onChange={handleChange} />
                            <FormHelperText>
                                {errors.reservation_date && touched.reservation_date && errors.reservation_date}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <TextField label="Godzina" name="reservation_time" type="time" value={values.reservation_time} onChange={handleChange} />
                            <FormHelperText>
                                {errors.reservation_time && touched.reservation_time && errors.reservation_time}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Telefon</InputLabel>
                            <Input
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                endAdornment={
                                    <InputAdornment>
                                        <Phone />
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {errors.phone && touched.phone && errors.phone}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <InputLabel>E-mail</InputLabel>
                            <Input
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                endAdornment={
                                    <InputAdornment>
                                        <Email />
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {errors.email && touched.email && errors.email}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="wishes">Specjalne życzenia</InputLabel>
                            <Input
                                id="wishes"
                                name="wishes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.wishes}
                                endAdornment={
                                    <InputAdornment>

                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>
                                {errors.wishes && touched.wishes && errors.wishes}
                            </FormHelperText>
                        </FormControl>
                        {displaySubmitButton && (
                            <>
                                {loader ? (
                                        <LoaderWrapper>
                                            <CircularProgress color="secondary" />
                                        </LoaderWrapper>
                                    ) :
                                    (
                                        <StyledButton onClick={handleSubmit}>Wyślij</StyledButton>
                                    )
                                }
                            </>
                        )}
                    </StyledForm>
                </>
            )}
        </Formik>
    );
}

export default ReservationForm;
