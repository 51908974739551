import {getDistance} from "geolib";

export const calcDistance = (lat,lng,restaurantLat,restaurantLng) => {
    if(lat != null && lng != null && restaurantLat != null && restaurantLng != null){
        return  getDistance(
            { latitude: lat, longitude: lng },
            { latitude: restaurantLat, longitude: restaurantLng}
        )/1000;
    }
    return null;
}
