import {KeyboardArrowRight, Payment} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import React from "react";

const PaymentAction = ({payment, setPayment, submit}) => {
    return <Button className="btn_payment"  onClick={(e)=>{
            setPayment(payment);
            submit(e)
        }}>
            {payment.logo ? (
                <img src={payment.logo} alt="online" />
            ): (
                <Payment />
            )}
            <span>{payment.description}</span>
            <KeyboardArrowRight />
        </Button>;
}

export default PaymentAction;
