const { FETCH_ALL_FOOD } = require("../constants/ActionTypes");

const initialState = {
  foods: [],
};

const foodReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_FOOD:
      return {
        foods: action.payload,
      };
    default:
      return state;
  }
};

export default foodReducer;
