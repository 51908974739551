import React from "react";
import Typography from "@material-ui/core/Typography";
// Assets

// Styles
import "./styles.scss";
import Button from "@material-ui/core/Button";
import styled from 'styled-components';
import TopImg from "../../Assets/Home/top.jpg";

const RestaurantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RestaurantContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  align-items: center;
`;
const RestaurantImageWrapper = styled.div`
  width: 100px;
  margin-right: 20px;
`;
const RestaurantName = styled.h4`
  text-align: left !important;
`;
const ButtonsWrapper = styled.div`
  margin-bottom: 20px;
`;

const ModalResetBasket = ({ setOpenModal, food, cart, addToCart, quantity }) => {

    const add = () => {
        addToCart(food, quantity, 1).finally(()=>{
            setOpenModal(false);
        });
    }

    return (
        <div className="sign">
            <div className="container">
                <div className="sign__warp">
                    <div className="sign__warp__form sign__warp--left">
                        <Typography className="LoginFont">Wyczyścić koszyk ?</Typography>
                        <p>Możesz jednocześnie złożyć zamówienie tylko do jednej restauracji. Wybierz restauracje z której chcesz zamówić!</p>

                        <RestaurantsWrapper>
                            <RestaurantContent>
                                <RestaurantImageWrapper>
                                    <img
                                        src={
                                            food.restaurant.media.length
                                                ? food.restaurant.media[0].url
                                                ? food.restaurant.media[0].url.split(".pl").join(".pl/")
                                                : TopImg
                                                : TopImg
                                        }
                                        width="100%"
                                        alt=""
                                    />
                                </RestaurantImageWrapper>
                                <div>
                                    <RestaurantName>{food.restaurant.name}</RestaurantName>
                                    <p>Wyczyść koszyk i zamów z tej restauracji</p>
                                </div>
                            </RestaurantContent>

                            <RestaurantContent>
                                <RestaurantImageWrapper>
                                    <img
                                        src={
                                            cart.food.restaurant.media.length
                                                ? cart.food.restaurant.media[0].url
                                                ? cart.food.restaurant.media[0].url.split(".pl").join(".pl/")
                                                : TopImg
                                                : TopImg
                                        }
                                        width="100%"
                                        className="restaurant__img"
                                        alt=""
                                    />
                                </RestaurantImageWrapper>
                                <div>
                                    <RestaurantName>{cart.food.restaurant.name}</RestaurantName>
                                    <p>Zachowaj zamówienie</p>
                                </div>
                            </RestaurantContent>
                        </RestaurantsWrapper>

                        <ButtonsWrapper>
                            <Button
                                onClick={()=>{
                                    add()
                                }}
                                type="small"
                                variant="contained"
                                className="btn__sign"
                            >
                                Dodaj do koszyka
                            </Button>

                            <Button
                                onClick={()=>{
                                    setOpenModal(false);
                                }}
                                type="small"
                                variant="contained"
                                className="btn__sign"
                            >
                                Anuluj
                            </Button>
                        </ButtonsWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalResetBasket;
