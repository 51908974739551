import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import userApi from "../../Api/userApi";
import {toast} from "react-toastify";
import RedirectPage from "../../Components/Redirect";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import * as Yup from "yup";

const ResetSchema = Yup.object().shape({
    email: Yup.string()
        .email("Niepoprawny format maila.")
        .required("Email jest wymagany.")
});

const Password = () => {
    const user = useSelector((state) => state.userReducer);
    const history = useHistory();
    const [loadingReset, setLoadingReset] = useState(false);

    const onReset = async (values) => {
            setLoadingReset(true);
        try {
            const user = await userApi.resetPassword(values);
            console.log(user);
            setLoadingReset(false);
            if (user.success) {
                toast.success("Link do zmiany hasła został wysłany.");
                history.push("/sign");
            } else {
                toast.error("Brak podanego e-maila w bazie.");
            }
        } catch (error) {
            toast.error("Błąd resetu hasła.");
            setLoadingReset(false);
            throw error;
        }
    };

    if (user.name) {
        return <RedirectPage to="/" />;
    }
    return (
        <div className="sign">
            <div className="container">
                <div className="sign__warp">
                    <div className="sign__warp__form sign__warp--left">
                        <Typography className="LoginFont">Reset hasła</Typography>
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={ResetSchema}
                            onSubmit={onReset}
                            className="alignCenter"
                        >
                            {({
                                  handleSubmit,
                                  errors,
                                  values,
                                  touched,
                                  handleBlur,
                                  handleChange,
                              }) => (
                                <>
                                    <TextField
                                        className="textInput"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={errors.email && touched.email && errors.email}
                                    />
                                    <Button
                                        onClick={handleSubmit}
                                        type="small"
                                        variant="contained"
                                        className="btn__sign"
                                    >
                                        {loadingReset && <CircularProgress size={18} />}
                                        {!loadingReset && "Wyślij Link"}
                                    </Button>
                                </>
                            )}
                        </Formik>

                        <Typography className="forget">
                            <a href="/sign">Wróć do logowania</a>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Password;
