import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import _ from "lodash";
import { useSelector } from "react-redux";

import "./style.scss";

const CookiePolicy = () => {
  const cookie = useSelector((state) => state.otherReducer.cookie_policy);
  return (
    <div className="cookie_policy">
      <div className="container">
        <Breadcrumb title="Polityka plików cookies" />
        {!_.isEmpty(cookie) && (
          <div className="cookie_policy--content">
            <div dangerouslySetInnerHTML={{ __html: cookie.question }} />
            <div dangerouslySetInnerHTML={{ __html: cookie.answer }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CookiePolicy;
