import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

// Assets

import Facebook from "../../Assets/Common/facebook.svg";
import Instagram from "../../Assets/Common/instagram.svg";

// Styles
import "./styles.scss";
import GrayIcon from "../Icons/GrayIcon";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_content">
          <div className="footer_content--col_1">
            <Typography className="part1">
              <p href="#" className="footerFont1 mb-20">
                INFORMACJE
              </p>
              <Link href="/regulations" className="footerFont mb-20">
                Regulamin
              </Link>
              <Link href="/brokerage-regulations" className="footerFont mb-20">
                Regulamin pośrednictwa
              </Link>
              <Link href="/cookie-policy" className="footerFont mb-20">
                Polityka plików cookies
              </Link>
            </Typography>
          </div>
          <div className="footer_content--col_2">
            <Typography className="part2">
              <p href="/restaurant-zone" className="footerFont1 mb-20">
                STREFA RESTAURACJI
              </p>
              <Link href="/benefit" className="footerFont mb-20">
                Korzyści dla Restauracji
              </Link>
              <Link href="/faq" className="footerFont mb-20">
                FAQ
              </Link>
            </Typography>
            <Grid item>
              <Link href="/dolacz" className="footer--link">
                <Button className="btn">
                  Dołącz
                </Button>
              </Link>
              <Link href="https://panelrestauracji.pl" className="footer--link">
                <Button className="btn">
                  Panel Restauracji
                </Button>
              </Link>
            </Grid>
          </div>
          <div className="footer_content--col-3">
            <Typography className="part2">
              <p href="#" className="footerFont1 mb-20">
                OBSŁUGA KLIENTA
              </p>
              <Link href="/kontakt" className="footerFont mb-20">
                Kontakt
              </Link>
              <Link href="/o-nas" className="footerFont mb-20">
                O nas
              </Link>
            </Typography>
          </div>
          <div className="footer_content--col-4">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.facebook.com/bookeatingpl" target="_blank">
              <GrayIcon src={Facebook} className="facebook1" alt="Facebook" />
            </a>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.instagram.com/bookeatingpl" target="_blank">
              <GrayIcon src={Instagram} className="instagram1" alt="Instagram"/>
            </a>
          </div>
        </div>
        <div className="copyright">
          <Typography className="text1">Copyright by BookEating</Typography>
          <Typography className="text2">
            Wykonanie: <a href="https://appcreo.pl" target="_blank" className="link">AppCreo</a>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
