import axiosClient from "./axiosClient";

export const cartApi = {
    getCart: (params) => {
        const url = "/carts?"+params;
        return axiosClient.get(url, {});
    },
    getCartQuest: (params) => {
        const url = "/guest_cart?"+params;
        return axiosClient.get(url, {});
    },

    addToCart: (data, params) => {
        const url = "/carts";
        let bodyFormData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value);
        }
        return axiosClient.post(url, bodyFormData, { params });
    },

    addToCartQuest: (data, params) => {
        const url = "/guest_cart/add";
        let bodyFormData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value);
        }
        return axiosClient.post(url, bodyFormData, { params });
    },

    removeCart: (id, params) => {
        const url = `/carts/${id}`;
        return axiosClient.delete(url, {params});
    },

    removeCartQuest: (id, params) => {
        const url = `/guest_cart/delete/${id}`;
        return axiosClient.delete(url, {params});
    },

    updateCart: (id, params) => {
        const url = `/carts/${id}`;
        return axiosClient.put(url, [], { params });
    },

    updateCartQuest: (id, params) => {
        const url = `/guest_cart/update/${id}`;
        return axiosClient.put(url, [], { params });
    }
};
