import React from "react";
import {Payment, Bookmark} from "@material-ui/icons";
import {payment} from "../../Models/Payment";
import PaymentAction from "../../Components/Checkout/PaymentAction";

const CartPayment = ({ values, handleSubmit,setPayment }) => {
  return (
      <div className="cart_payment fade-in">
          <div className="cart_payment--online">
              <div className="cart_payment--title">
                  <Payment />
                  <span>Płatność online</span>
              </div>
              {payment.paymentsOnline().map((item)=> (
                  <PaymentAction key={item.id} payment={item} setPayment={setPayment} submit={handleSubmit}/>
              ))}
          </div>
          {(values.shippingMethod === 'store' || values.shippingMethod === 'reservation') && (
              <div className="cart_payment--offline">
                  <div className="cart_payment--title">
                      <Bookmark />
                      <span>Odbiór osobisty</span>
                  </div>
                  {payment.paymentPickup().map((item)=> (
                      <PaymentAction key={item.id} payment={item} setPayment={setPayment} submit={handleSubmit}/>
                  ))}
              </div>
          )}
          {values.shippingMethod === 'place' && (
              <div className="cart_payment--offline">
                  <div className="cart_payment--title">
                      <Bookmark />
                      <span>Płatność przy odbiorze</span>
                  </div>
                  {payment.paymentsCash().map((item)=> (
                      <PaymentAction key={item.id} payment={item} setPayment={setPayment} submit={handleSubmit}/>
                  ))}
              </div>
          )}
      </div>
  );
};

export default CartPayment;
