import React, { useEffect, useState } from "react";
import MenuList from "./MenuList";
import { useLocation } from "react-router-dom";
import MenuDetail from "./MenuDetail";
import userApi from "../../Api/userApi";
import {useSelector} from "react-redux";

const MenuRestaurant = () => {
  const user = useSelector((state) => state.userReducer);
  const location = useLocation();
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [change, setChange] = useState(1);
  const [favorites, setFavorites] = useState([]);

  const updateChange = () => {
    setChange(change+1);
  }

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("food")) {
        setIsShowDetail(true);
      } else setIsShowDetail(false);
    }
    if(user.api_token){
      userApi.favorites({
        api_token: user.api_token,
        with:"food;user;extras",
        search:`user_id:${user.id}`,
        searchFields: `user_id:=`
      }).then((response) => {
        const {data:newFoods} = response;
        setFavorites(newFoods);
      })
    }
  }, [location,change,user.api_token]);

  return <>
    {isShowDetail ? <MenuDetail favorites={favorites} setIsShowDetail={setIsShowDetail} updateChange={updateChange} /> : <MenuList />}
    </>;
};

export default MenuRestaurant;
