import React, {useEffect, useState} from "react";
import _ from "lodash";
import Breadcrumb from "../../Components/Breadcrumbs";

import "./style.scss";
import otherApi from "../../Api/otherApi";

const BrokerageRegulations = () => {
    const [regulations,setRegulations] = useState(null);

    useEffect(()=>{
        otherApi.getBrokerageRegulationsRegulations().then((response) => {
            console.log(response);
            setRegulations(response.data);
        })
    },[])

    return (
        <div className="regulations">
            <div className="container">
                <Breadcrumb title="Regulamin" />
                {!_.isEmpty(regulations) && (
                    <div className="regulation--content">
                        <div dangerouslySetInnerHTML={{ __html: regulations.question }} />
                        <div dangerouslySetInnerHTML={{ __html: regulations.answer }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrokerageRegulations;
