import axiosClient from "./axiosClient";

const reviewApi = {
  getRestaurant: (params) => {
    const url = "/restaurant_reviews";
    return axiosClient.get(url, { params });
  },
  getFood: (params) => {
    const url = "/food_reviews";
    return axiosClient.get(url, {params})
  } 
};

export default reviewApi;
