import React from "react";
import Typography from "@material-ui/core/Typography";
import "./styles.scss";
import styled from 'styled-components';

const RegulationWrapper = styled.div`
    overflow-y: scroll;
    height: 500px;
    padding: 20px;
`;

const StyledModalForm = styled.div`
  max-width: 1000px !important;
`;


const ModalRegulation = ({ regulation }) => {
    return (
        <div className="sign">
            {regulation && (
                <div className="container">
                    <div className="sign__warp">
                        <StyledModalForm className="sign__warp__form sign__warp--left">
                            <Typography className="LoginFont">Regulamin</Typography>
                            <RegulationWrapper>
                                <div dangerouslySetInnerHTML={{
                                    __html: regulation
                                }}/>
                            </RegulationWrapper>
                        </StyledModalForm>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalRegulation;
