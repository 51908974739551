import {
  FETCH_ALL_FOOD_REVIEW,
  FETCH_ALL_RESTAURANT_REVIEW,
} from "../constants/ActionTypes";

const initialState = {
  restaurant_reviews: [],
  food_reviews: [],
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_FOOD_REVIEW:
      return { ...state, food_reviews: action.payload };
    case FETCH_ALL_RESTAURANT_REVIEW:
      return { ...state, restaurant_reviews: action.payload };
    default:
      return state;
  }
};

export default reviewReducer;
