import React from "react";
import styled from 'styled-components';

const StyledImg = styled.img`
  filter: grayscale(100%);
  :hover{
    filter: grayscale(0%);
    transform: scale(1.1);
  }
`;

const GrayIcon = (params) => {
    return (
        <StyledImg {...params} />
    );
}

export default GrayIcon;
