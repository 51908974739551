import axiosClient from "./axiosClient";

const foodApi = {
  getAll: () => {
    const url = "/foods";
    return axiosClient.get(url);
  },
};

export default foodApi;
