import React, {useEffect, useState} from "react";
import { Button } from "@material-ui/core";
import NotificationsSharpIcon from "@material-ui/icons/NotificationsSharp";
import { useSelector } from "react-redux";
import userApi from "../../Api/userApi";
import styled from 'styled-components';
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusDate = styled.span`
  font-size: 0.7rem;
`;

const Notification = () => {
  const user = useSelector((state) => state.userReducer);
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    userApi.notifications({
      api_token: user.api_token,
      search:`notifiable_id:${user.id}`,
      searchFields:`notifiable_id:=`,
      orderBy:`created_at`,
      sortedBy:`desc`,
      limit:`10`,
    }).then((response) => {
      const {data:newNotifications} = response;
      setNotifications(newNotifications);
    }).finally(()=>{
      setLoader(false);
    })
  }, []);

  const displayStatus = (text) => {
    switch (text) {
      case "App\\Notifications\\StatusChangedOrder":
        return "Zmiana statusu zamówienia";
      case "App\\Notifications\\NewOrder":
        return "Nowe zamówienie";
      case "km":
        return "Km";
      case "mi":
        return "mi";
      default:
        return "";
    }
  }

  return (
    <div className="customer_notification fade-in">
      {loader && (
          <LoaderWrapper>
            <CircularProgress color="secondary" />
          </LoaderWrapper>
      )}
      {notifications.map((notify) => (
        <Button className="seen" key={notify.id}>
          <NotificationsSharpIcon />
          <StatusWrapper>
            {displayStatus(notify.type)}
            <StatusDate>{notify.updated_at}</StatusDate>
          </StatusWrapper>
        </Button>
      ))}
    </div>
  );
};

export default Notification;
