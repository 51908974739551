import {cartApi} from "../Api/cartApi";

export const cart = {
    getCart: (user) => {
        if(user.api_token){
            return cartApi.getCart(`api_token=${user.api_token}&with=food;food.restaurant;extras&search=user_id:${user.id}&searchFields=user_id:=`)
        }
        else{
            let questUserId = user.guest_user_id;
            return cartApi.getCartQuest(`with=food;food.restaurant;extras&search=user_id:${questUserId}&searchFields=user_id:=`)
        }
    },

    addToCart: (userReducer, product, qty, reset) => {
        if(userReducer.api_token){
            return cartApi.addToCart({
                quantity:qty,
                food_id: product.id,
                user_id: userReducer.id
            },{api_token: userReducer.api_token, reset: reset})
        }
        else{
            let questUserId = userReducer.guest_user_id;
            return cartApi.addToCartQuest({
                quantity:qty,
                food_id: product.id,
                user_id: questUserId
            },{reset: reset})
        }
    },

    remove: (id, user) => {
        if(user.api_token){
            return cartApi.removeCart(id, {
                api_token:user.api_token
            })
        }
        else{
            let questUserId = user.guest_user_id;
            return cartApi.removeCartQuest(id, {
                user_id: questUserId
            })
        }
    },

    incrementQty: (cart, user) => {
        if(user.api_token){
            return cartApi.updateCart(cart.id,{
                api_token:user.api_token,
                food_id: cart.food_id,
                user_id: user.id,
                quantity: (cart.quantity + 1)
            })
        }
        else{
            let questUserId = user.guest_user_id;
            return cartApi.updateCartQuest(cart.id,{
                api_token:user.api_token,
                food_id: cart.food_id,
                user_id: questUserId,
                quantity: (cart.quantity + 1)
            })
        }
    },
    decrementQty: (cart, user) => {
        if(user.api_token){
            return cartApi.updateCart(cart.id,{
                api_token:user.api_token,
                food_id: cart.food_id,
                user_id: user.id,
                quantity: (cart.quantity - 1)
            })
        }
        else{
            let questUserId = user.guest_user_id;
            return cartApi.updateCartQuest(cart.id,{
                food_id: cart.food_id,
                user_id: questUserId,
                quantity: (cart.quantity - 1)
            })
        }
    },

    canAddToBasket: (cart, food) => {
        if(cart.length > 0 ){
            let restaurant_id = cart[0].food.restaurant_id;
            if(restaurant_id === food.restaurant_id){
                return true;
            }
            return false;
        }
        return true;
    },

    calcShippingPrice: (restaurant, distance, price, reservation) => {
        if(reservation){
            return 0.0;
        }
        if(restaurant && distance != null){
            if(!restaurant.available_for_delivery){
                return 0.0;
            }
            if(price >= restaurant.delivery_fee){
                return  0.0;
            }
            if(distance > restaurant.delivery_range){
                return  0.0;
            }
            const delivery = restaurant.delivery_charges_list.find((item) => {
                let from = parseFloat(item.starting_price);
                let to = parseFloat(item.ending_price);
                if(from <= distance && to >= distance){
                    return item;
                }
                return null;
            })
            if(delivery){
                return parseFloat(delivery.delivery_charge)
            }
            return null;
        }
    }
}
