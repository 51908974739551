import React, {useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {Favorite} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import TopImage from "../../Assets/Home/top.jpg";
import {useSelector} from "react-redux";
import userApi from "../../Api/userApi";
import styled from "styled-components";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {toast} from "react-toastify";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const HeartButton = styled.button`
    color: #d1402a;
    position: absolute;
    right: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    outline:none;
    svg{
      width: 45px;
      height: 65px;
    }
`;

const Favorites = () => {
    const user = useSelector((state) => state.userReducer);
    const [foods, setFoods] = useState([]);
    const [loader, setLoader] = useState(true);
    const [change, setChange] = useState(1);

    useEffect(() => {
        userApi.favorites({
            api_token: user.api_token,
            with:"food;user;extras",
            search:`user_id:${user.id}`,
            searchFields: `user_id:=`
        }).then((response) => {
            const {data:newFoods} = response;
            setFoods(newFoods);
        }).finally(()=>{
            setLoader(false);
        })
    }, [change]);

    const removeFavorite = (id) => {
        userApi.removeFavorites(id,{
            api_token: user.api_token
        }).then(() => {
            toast.success("Usunięto z ulubionych.");
        }).finally(()=>{
            setChange(change+1);
        })
    }

    return (
        <>
            <Typography className="title__favorites">
                <Favorite />
                Ulubione
            </Typography>
            {loader && (
                <LoaderWrapper>
                    <CircularProgress color="secondary" />
                </LoaderWrapper>
            )}
            <Grid className="wrap__favorites fade-in" container spacing={4}>
                {foods.map(({id, food}) => (
                        <Grid
                            className="favorites__restaurant"
                            item
                            key={id}
                            index={id}
                            xs={6}
                            sm={4}
                            md={3}
                        >
                            <div>
                                <ImgWrapper>
                                    <HeartButton onClick={()=>{
                                        removeFavorite(id);
                                    }}>
                                        <FavoriteIcon />
                                    </HeartButton>
                                    <Link
                                        to={`/restaurants/${food.restaurant_id}/food#${food.id}`}
                                        style={{ textDecoration: "none", color: "#000" }}
                                    >
                                        <img
                                            src={
                                                food.media.length
                                                    ? food.media[0].url
                                                    ? food.media[0].url.split(".pl").join(".pl/")
                                                    : TopImage
                                                    : TopImage
                                            }
                                            height="76px"
                                            width="100%"
                                            alt=""
                                        />
                                    </Link>
                                </ImgWrapper>
                                <Grid className="wrap__name">
                                    <Typography>{food.name}</Typography>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: food.description,
                                        }}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default Favorites;
