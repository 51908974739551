import {CircularProgress, Input, InputLabel} from "@material-ui/core";
import {Formik} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";

const SignInSchema = Yup.object().shape({
    email: Yup.string()
        .email("Niepoprawny format maila.")
        .required("Email jest wymagany."),
    name: Yup.string()
        .required("Imię jest wymagane."),
    phone: Yup.string()
        .required("Telefon jest wymagane."),
    title: Yup.string()
        .required("Temat jest wymagany."),
    description: Yup.string()
        .required("Opis jest wymagany."),
});

const StyledForm = styled.form`
  max-width: 100% !important;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    margin: 5px 0;
  }
`;

const Title = styled.h2`
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: ${props => props.color}
`;

const StyledButton = styled.button`
  background-color: ${props => props.color};
  border-color: ${props => props.color};
  border-width: 0;
  width: 168px;
  height: 45px;
  margin-top: 10px;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    background-position: left bottom;
    background-color: rgba(0, 0, 0, 0.04);
    transition: all ease 0.3s;
  }
`;

export const ContactForm = ({title, color, onSubmit}) => {
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={{
                name: "",
                email: "",
                phone: "",
                title: "",
                description: "",
            }}
            validationSchema={SignInSchema}
            onSubmit={(values, {resetForm}) => {
                onSubmit(values, setLoading, resetForm)
            }
            }
            className="alignCenter"
        >
            {({
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  handleBlur,
                  handleChange,
              }) => (
                <StyledForm>
                    <Title color={color}>{title}</Title>
                    <FormControl>
                        <InputLabel htmlFor="name">Imię</InputLabel>
                        <Input
                            id="name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                        />
                        <FormHelperText>
                            {errors.name && touched.name && errors.name}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputLabel htmlFor="email">E-mail</InputLabel>
                        <Input
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <FormHelperText>
                            {errors.email && touched.email && errors.email}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputLabel htmlFor="phone">Telefon</InputLabel>
                        <Input
                            id="phone"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                        />
                        <FormHelperText>
                            {errors.phone && touched.phone && errors.phone}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputLabel htmlFor="title">Temat</InputLabel>
                        <Input
                            id="title"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                        />
                        <FormHelperText>
                            {errors.title && touched.title && errors.title}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputLabel htmlFor="description">Treść</InputLabel>
                        <Input
                            id="description"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                        />
                        <FormHelperText>
                            {errors.description && touched.description && errors.description}
                        </FormHelperText>
                    </FormControl>

                    <StyledButton
                        color={color}
                        onClick={handleSubmit}
                        type="small"
                        variant="contained"
                        className="btn__sign"
                    >
                        {loading && <CircularProgress size={18}/>}
                        {!loading && "Wyślij"}
                    </StyledButton>
                </StyledForm>
            )}
        </Formik>
    );
}
