import {
  FETCH_BENEFIT, FETCH_CONTACT,
  FETCH_COOKIE_POLICY,
  FETCH_O_NAS,
  FETCH_PRIVACY_POLICY,
  FETCH_REGULATIONS,
  FETCH_RESTAURANT_ZONE,
} from "../constants/ActionTypes";

const initialState = {
  regulations: {},
  privacy_policy: {},
  cookie_policy: {},
  benefit: {},
  o_nas: {},
  restaurant_zone: {},
  contact: {},
};

const otherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGULATIONS:
      return { ...state, regulations: action.payload };
    case FETCH_PRIVACY_POLICY:
      return { ...state, privacy_policy: action.payload };
    case FETCH_COOKIE_POLICY:
      return { ...state, cookie_policy: action.payload };
    case FETCH_CONTACT:
      return { ...state, contact: action.payload };
    case FETCH_BENEFIT:
      return { ...state, benefit: action.payload };
    case FETCH_O_NAS:
      return { ...state, o_nas: action.payload };
    case FETCH_RESTAURANT_ZONE:
      return { ...state, restaurant_zone: action.payload };
    default:
      return state;
  }
};

export default otherReducer;
